.valueWrapper {
    width: 100%;
    margin: 8px 0;
}

.valueWrapper .value {
    padding: 4px;
    border-radius: 4px;
    border: none;
    height: 100%;
    outline: none;
}

.valueWrapper .percentageWrapper {
    float: right;
    width: 50px;
    padding: 0 4px;
    border-radius: 4px;
    background-color: #ffffff;
}

.valueWrapper .percentageWrapper .percentage {
    padding: 4px;
    border-radius: 4px;
    border: none;
    text-align: right;
    background-color: #ffffff00;
    float: right;
    outline: 0;
}

.valueWrapper .percentageWrapper .sign {
    float: right;
    position: relative;
    color: #000000;
    font-weight: bold;
}
