#wrapper {
    border: none;
    width: 200px;
    height: 56px;
    position: relative;
    border-radius: 8px;
    font-size: 32px;
    font-weight: 400;
    line-height: normal;
    background-color: #ffffff4d;
    outline: none;
    box-shadow: 0 4px 20px 0 transparent;
    transition: 0.3s all;
    -webkit-appearance: none;
    color: #ffffff;
}

#number {
    height: 100%;
    width: 10px;
    display: inline-block;
    padding: 0 24px 0 12px;
}

#input {
    border: none;
    height: 54px;
    width: 140px;
    display: inline-block;
    padding-right: 16px;
    position: relative;
    border-radius: 8px;
    font-size: 32px;
    font-weight: 400;
    line-height: normal;
    background-color: #ffffff00;
    outline: none;
    box-shadow: 0 4px 20px 0 transparent;
    transition: 0.3s all;
    -webkit-appearance: none;
    color: #ffffff;
    z-index: 10;
}

#input::placeholder {
    color: #ffffff;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}
