#tableWrapper {
    border: none;
    width: 90%;
    position: relative;
    padding: 8px;
    border-radius: 8px;
    background-color: #ffffff4d;
    outline: none;
    box-shadow: 0 4px 20px 0 transparent;
    transition: 0.3s all;
    -webkit-appearance: none;
}

td {
    padding: 4px;
    background-color: #ffffff4f;
    text-align: center;
}

.topLeft {
    border-top-left-radius: 8px;
}

.topRight {
    border-top-right-radius: 8px;
}

.bottomLeft {
    border-bottom-left-radius: 8px;
}

.bottomRight {
    border-bottom-right-radius: 8px;
}

.checked {
    background-color: #ffffffff;
}

td span {
    font-size: 10px;
    font-weight: 400;
    font-family: sans-serif;
    line-height: 5px;
}

.title {
    font-size: 14px;
}
