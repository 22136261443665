.tableWrapper {
    overflow-x: scroll;
    overflow-y: hidden;
    white-space: nowrap;
    margin: 20px -20px 20px 0;
}

.tableWrapper::-webkit-scrollbar {
    display: none;
}

.tableWrapper table:first-child tr td:first-child, .tableWrapper table:first-child tr th:first-child {
    position: sticky;
    left: 0;
    z-index: 0;
    background-color: #424242;
    box-shadow: inset -1px 0 #555555;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}
