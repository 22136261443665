#newBingoWrapper {
    font-family: sans-serif;
    font-size: 20px;
}

li {
    margin: 8px
}

#slider {
    -webkit-appearance: none;
    width: 90%;
    height: 12px;
    border-radius: 5px;
    background: #ffffff;
    outline: none;
    -webkit-transition: .2s;
    transition: opacity .2s;
    margin: 16px;
}

#slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background: #cccccc;
    cursor: pointer;
}

#slider::-moz-range-thumb {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background: #bbbbbb;
    cursor: pointer;
}

code {
    background-color: #ffffff55;
    padding: 0 4px;
    border-radius: 4px;
}

#loader {
    border: 4px solid #ffffff;
    border-top: 4px solid #bbbbbb;
    border-radius: 50%;
    width: 16px;
    height: 16px;
    animation: spin 0.75s linear infinite;
    margin-bottom: -8px;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}
