#winnerPromptWrapper {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background-color: #00000088;
    z-index: 5;
    align-items: center;
    justify-content: center;
    display: flex;
    flex: 1;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}

#winnerPromptWrapper #box {
    position: absolute;
    width: 300px;
    height: calc(300px - 32px);
    margin-left: auto;
    margin-right: auto;
    top: 100px;
    z-index: 10;
    text-align: center;
    background-color: #3d6db4;
    border-radius: 16px;
    padding: 16px 0;
}

#winnerPromptWrapper #box #winnerValue {
    font-size: 32px;
    margin-top: 50px;
}

#winnerPromptWrapper #box button {
    width: 150px;
    position: absolute;
    bottom: 16px;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    padding: 8px;
    border-radius: 8px;
    border: none;
    background-color: #d32f2f;
    color: #ffffff;
}
