#optionsWrapper {
    width: 90%;
    max-width: 400px;
    /*background-color: #ffffff33;*/
    padding: 16px;
    border-radius: 8px;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    color: #ffffff;
}

#optionsWrapper hr {
    border-color: #ffffff55;
}

#optionsWrapper #options #equalChance input {
    float: right;
    transform: scale(1.5);
}

#optionsWrapper #options #nrOfPieces input {
    padding: 4px;
    border-radius: 4px;
    border: none;
    text-align: right;
    position: absolute;
    right: 0;
    top: 0;
}

#optionsWrapper #values #count button {
    width: 40%;
    padding: 8px;
    border-radius: 8px;
    border: none;
    color: #ffffff;
}

#optionsWrapper #values #count #delete {
    background-color: #d32f2f;
}

#optionsWrapper #values #count #add {
    float: right;
    background-color: #2e7b32;
 }
