#spotifyLoginButton {
    text-decoration: none;
    background-color: #1db954;
    padding: 20px 40px;
    border-radius: 32px;
    font-family: sans-serif;
    color: #ffffff;
    font-size: 14px;
    font-weight: bolder;
}
